import React, { useMemo, useState } from 'react';

import { ClickAway } from '@/components/ui/click-away';
import ChevronDownIcon from '@/icons/chevron-down';
import clsx from 'clsx';
import DropdownContainer, { DropdownData } from '../dropdown-container';

export interface FiltersDropdownProps {
  data: DropdownData[];
  selectedValue: string | string[] | null;
  onChangeValue?: (value: string | string[]) => void;
  label?: string;
  error?: string;
  dense?: boolean;
  multiselect?: boolean;
  position?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
}

export function Dropdown({
  data,
  selectedValue,
  onChangeValue,
  error,
  label,
  dense,
  multiselect,
  position,
}: FiltersDropdownProps) {
  const [show, setShow] = useState(false);

  const selectedItems = useMemo(() => {
    let result = [];
    if (
      !selectedValue ||
      (Array.isArray(selectedValue) && selectedValue.length === 0)
    ) {
      result = [data.find((x) => !x.value)];
    } else if (typeof selectedValue === 'string') {
      result = [data.find((item) => item.value === selectedValue)];
    } else if (Array.isArray(selectedValue)) {
      result = data.filter((item) => selectedValue.includes(item.value));
    }

    result = result.filter((x) => !!x);

    if (!result[0]) {
      result = [
        {
          label: '',
          value: '',
        },
      ];
    }

    return result;
  }, [data, selectedValue]);

  const toggleDropdown = () => {
    setShow((prev) => !prev);
  };

  const handleClickAway = () => {
    if (show) {
      setShow(false);
    }
  };

  return (
    <ClickAway
      onClickAway={handleClickAway}
      className="relative w-full"
      value={show}
    >
      {!!label && (
        <div className="mb-1 text-xs text-gray-400">
          <span>{label}</span>
        </div>
      )}
      <div className="relative">
        <button
          onClick={toggleDropdown}
          type="button"
          aria-label="Dropdown"
          className={clsx(
            'border w-full text-left px-4 rounded flex justify-between items-center',
            dense ? 'py-2' : 'py-3'
          )}
        >
          <span
            className={clsx('overflow-hidden text-ellipsis whitespace-nowrap', {
              'text-gray-400': selectedItems[0]?.disabled,
            })}
          >
            {selectedItems.map((x) => x.label).join(', ')}&nbsp;
          </span>
          <ChevronDownIcon
            className={clsx(
              'h-4 text-gray-600 fill-current transform duration-200',
              { ['-rotate-180']: show }
            )}
          />
        </button>
        {!!error && <label className="text-sm text-red-500">{error}</label>}

        <DropdownContainer
          label={label}
          isShow={show}
          data={data}
          selectedItems={selectedItems}
          selectedValue={selectedValue}
          isDance={dense}
          isMultiselect={multiselect}
          onChangeValue={onChangeValue}
          toggleDropdown={toggleDropdown}
          position={position}
        />
      </div>
    </ClickAway>
  );
}
