import {
  DayValue,
  DatePickerProps as ModernDatePickerProps,
} from '@hassanmojab/react-modern-calendar-datepicker';
import dynamic from 'next/dynamic';
import colors from 'tailwindcss/colors';
import {
  FORMAT_DATE1,
  convertToModernDatePickerFormat,
  formatDate,
} from '@/utility/date';
import classes from './date-picker.module.css';

const LazyModernDatePicker = dynamic<ModernDatePickerProps<DayValue>>(
  () => import('@hassanmojab/react-modern-calendar-datepicker')
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export interface DatePickerProps {
  htmlFor: string;
  value: Date;
  label?: string;
  required?: boolean;
  error?: string;
  touched?: boolean;
  minimumDate?: Date;
  shouldHighlightWeekends?: boolean;
  inputPlaceholder?: string;
  onChange?: (value: Date) => void;
}

export function DatePicker({
  label,
  htmlFor,
  error,
  touched,
  onChange,
  value,
  minimumDate,
  ...props
}: DatePickerProps) {
  return (
    <div className="z-10">
      {!!label && (
        <label
          htmlFor={htmlFor}
          className="block font-medium mb-1 text-xs text-gray-400"
        >
          {label}
        </label>
      )}
      <LazyModernDatePicker
        {...props}
        value={convertToModernDatePickerFormat(value)}
        minimumDate={convertToModernDatePickerFormat(minimumDate)}
        onChange={(value) => {
          onChange &&
            onChange(new Date(value.year, value.month - 1, value.day));
        }}
        colorPrimary={colors.blue[500]}
        inputClassName={classes.input}
        wrapperClassName="w-full"
        formatInputText={() => formatDate(value, FORMAT_DATE1)}
      />
      {!!error && !!touched && (
        <span className="text-red-500 text-sm block my-2">{error}</span>
      )}
    </div>
  );
}
